@media (min-width: 768px) {
  .audit-report-table .filter-bar-left {
    width: 90px;
    float: left;
  }
  .audit-report-table .filter-bar-left {
    width: 90px;
    float: left;
  }
  .audit-report-table .filter-bar-center {
    width: calc(100% - 300px);
  }
  .audit-report-schedule-filter-bar-row .filter-bar-right {
    width: 205px;
    float: left;
  }
}
.audit-report-table .dateRange-item .filter-item-title {
  width: 128px;
}
.audit-report-table .dateRange-item input {
  width: 100% !important;
}
.rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
  border-color: var(--cui-accent-color) !important;
}

.filter-bar-row {
  align-items: center;
}

.filter-bar-left {
  align-self: flex-start;
}

.ag-cell-overflow-visible .ag-cell-value {
  overflow: visible !important;
}
.company-account-review-table .ag-root-wrapper.ag-layout-normal,
.company-account-review-table .ag-root.ag-layout-normal {
  /* overflow: visible !important; */
}
