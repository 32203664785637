@media (min-width: 768px) {
  .users-manage .filter-bar-center {
    width: calc(100% - 535px);
  }
  .users-manage .filter-bar-left {
    width: 230px;
    float: left;
  }
  .users-manage .filter-bar-right {
    width: 305px;
    float: left;
  }
}
.users-manage .filter-bar-right {
  display: flex;
}
.users-manage .filter-bar-right-content {
  display: inline-block;
  align-self: flex-end;
}
.users-manage .users-details-table .table thead tr th,
.users-manage .users-details-table .table thead tr td {
  text-align: left !important;
}
